import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix"
import "./index.css"
import { ReactNode } from "react"
import { Container } from "styled-system/jsx"
import { Layout as StyledLayout } from "~/components/lifeplan/layout"
import { GoogleTagManager } from "~/components/trackers/google-tag-manager"
import {
  ERROR_CODE,
  GraphQLExecutionResultError,
  isGraphQLError,
} from "~/lib/error"

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin={"anonymous"}
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+Mono:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif+JP:wght@200..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>
        <link rel="icon" href="/favicon.svg"></link>
        <Meta />
        <Links />
      </head>
      <body>
        <GoogleTagManager
          id={import.meta.env.VITE_GTM_ID}
          auth={import.meta.env.VITE_GTM_AUTH}
          preview={import.meta.env.VITE_GTM_PREVIEW}
        />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  let errorMessage =
    "エラーが発生しました。しばらくしてから再度お試しください。"

  if (error instanceof GraphQLExecutionResultError) {
    if (isGraphQLError(error, ERROR_CODE.NOT_FOUND)) {
      errorMessage = "ページが見つかりません。URLをご確認ください。"
    }
  }

  return (
    <StyledLayout>
      <Container>{errorMessage}</Container>
    </StyledLayout>
  )
}

export function HydrateFallback() {
  return <p>Loading...</p>
}

function App() {
  return <Outlet />
}

export default withSentry(App)
