import React from "react"
import { APP_ENV_PROD } from "~/lib/const"

export type GoogleTagManagerProps = {
  /** GTM ID
   *
   * 指定が無い場合は何もしません
   */
  id?: string
  /** GTMの環境ごとの設定
   *
   * 詳細: https://support.google.com/tagmanager/answer/6311518?hl=ja
   * 本番環境には Live（公開中）の値を設定してください。
   */
  auth?: string
  preview?: string
}

/** Google Tag Managerのタグ
 *
 * Bodyのすぐ下に挿入してください。
 * また、下記URLを参考にTagManagerを設定してください。
 *
 * - SPA用の設定: https://note.com/kensuke_tsutsumi/n/n563258e54dac
 * - 外部リンクのトラッキング用設定: https://asiastars.org/%E3%82%BF%E3%82%B0%E3%83%9E%E3%83%8D/%E3%82%BF%E3%82%B0%E3%83%9E%E3%83%8D%E3%81%A7%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E8%A8%AD%E5%AE%9A%E5%85%B7%E4%BD%93%E4%BE%8B/
 * - クロスドメインの設定: https://www.officeants.com/blog/googleanalytics-crossdomain-tracking.html#mokuji4
 *  */
export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  id,
  auth = "",
  preview = "",
}) => {
  if (!id || process.env.NODE_ENV !== APP_ENV_PROD) return null

  const gtm_auth = `&gtm_auth=${auth}`
  const gtm_preview = `&gtm_preview=${preview}`
  const script = `
      window.dataLayer ||= [];
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
  `
  return (
    <script
      async
      id="gtm"
      dangerouslySetInnerHTML={{
        __html: `${script}`,
      }}
    />
  )
}
